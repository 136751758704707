<template>
  <div class="tip-container">
    <div class="header">
      <el-icon @click="handleCancel"><ArrowLeft /></el-icon>
      <span>{{ t('tip') }}</span>
    </div>
    <div class="title-container">
      <div class="title">
        <i class="el-icon-circle-check" style="font-size: 40px"></i>
        <div>{{ t('register_tip1') }}</div>
        <div>{{ t('register_tip2') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ArrowLeft } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const router = useRouter()
const handleCancel = () => {
  router.push({ path: '/login' })
}
</script>

<style lang="scss" scoped>
.tip-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .header {
    display: flex;
    align-items: center;
    margin: 32px;
    .el-icon {
      padding: 14px;
      color: var(--icon-color);
      width: 52px;
      height: 52px;
      border: 2px solid var(--border-el-primary);
      border-radius: 4px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: 24px;
      }
    }
    span {
      font-size: 30px;
      color: var(--font-color-active);
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 700;
      margin-left: 32px;
    }
  }

  .title-container {
    position: relative;

    .title {
      width: 600px;
      font-size: 26px;
      color: var(--font-color-active);
      margin: 100px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
